<template>
    <component :is="currentComponent"></component>
</template>

<script>
import {defineAsyncComponent} from "vue";

export default {
    name: "GeneralSettings",
    computed: {
        currentComponent() {
            return 'ViewAllSettingsComponent'
        },
    },
    components: {
        ViewAllSettingsComponent: defineAsyncComponent(() =>
            import("@/views/settings/generalsettings/ViewAllSettings")
        ),
    }
}
</script>

<style scoped>

</style>